<template>
    <v-card
        :loading="loading"
        :disabled="loading"
        height="100%"
        class="flexcard"
        elevation="4"
    >
        <v-card-text class="grow" :class="{ 'pa-0': noPadding && (!loading || noSkeleton)}">
            <div
                v-if="$slots.feature && (!loading || (loading && noSkeleton))"
                class="float-right"
            >
                <slot name="feature" />
            </div>
            <p
                v-if="title && (!loading || (loading && noSkeleton))"
                class="overline mb-2"
                :class="{ 'pt-4 pl-4': noPadding}"
            >
                {{ title }}
            </p>
            <v-skeleton-loader
                v-if="title && loading && !noSkeleton"
                type="text"
            ></v-skeleton-loader>
            <slot v-if="!loading || (loading && noSkeleton)" />
            <div v-if="loading && !noSkeleton">
                <v-skeleton-loader type="heading"></v-skeleton-loader>
                <br />
                <v-skeleton-loader type="paragraph"></v-skeleton-loader>
            </div>
        </v-card-text>
        <v-divider v-if="$slots.actions" />
        <v-card-actions v-if="$slots.actions" class="justify-end flex-wrap">
                <HelpButton
                    v-if="showHelp"
                    :tooltip="helpTooltip"
                    @click="$emit('help')"
                    class="mr-auto"
                />

                <slot v-if="!loading || (noSkeleton && loading)" name="actions" />
                <div v-if="loading && !noSkeleton">
                    <v-skeleton-loader
                            type="button"
                            style="float: right; padding-left: 10px"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                            type="button"
                            style="float: right; padding-left: 10px"
                    ></v-skeleton-loader>
            </div>

        </v-card-actions>
    </v-card>
</template>

<script>
import HelpButton from '@/components/pieces/HelpButton';
export default {
    name: 'BasicCard',
    components: { HelpButton },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        showHelp: {
            type: Boolean,
            default: false,
        },
        helpTooltip: {
            type: String,
            default: "What's this?",
        },
        noSkeleton: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
