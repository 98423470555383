<template>
    <v-form v-model="valid" v-on:submit.prevent="" ref="contactForm" >
        <v-row dense>
            <v-col cols="12" sm="2">
                <v-combobox
                    v-model="contact.salutation"
                    label="Title"
                    :items="data.salutations"
                    :rules="rules.salutation"
                    hint="Optional"
                    outlined
                />
            </v-col>
            <v-col cols="12" sm="5">
                <v-text-field
                    ref="contactFormFirstName"
                        v-model="contact.firstname"
                        label="First Name *"
                        hint="Required"
                        outlined
                        :rules="rules.firstname"
                />
            </v-col>
            <v-col cols="12" sm="5">
                <v-text-field
                    ref="contactFormLastName"
                        v-model="contact.lastname"
                        label="Last Name *"
                        hint="Required"
                        outlined
                        :rules="rules.lastname"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                        v-model="contact.phone1"
                        :label="'Business Phone' + (!contact.phone2 ? ' *' : '')"
                        outlined
                        :rules="(!!contact.phone1 || !contact.phone2) ? rules.phone : []"
                        hint="Geographic(area code) or 13/1300/1800 - no mobiles"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="contact.phone2"
                    :label="'Mobile Phone' + (!contact.phone1 ? ' *' : '')"
                    outlined
                    :rules="(!!contact.phone2 || !contact.phone1) ? rules.mobile : []"
                    hint="SMS alerts (such as suspension warnings) will be sent here"
                />
            </v-col>
            <v-col cols="12">
                <v-text-field
                    v-model="contact.email"
                    label="Email *"
                    outlined
                    :rules="rules.email"
                    :hint="(hints.email == null ? 'This must be an end-user email - no partner emails. Bills and account notifications will be sent to this address.' : hints.email)"
                />
            </v-col>
            <v-col cols="12" v-if="showBcc">
                <v-text-field
                    v-model="contact.bcc"
                    label="BCC Email"
                    outlined
                    :rules="rules.bcc"
                    :error-messages="(!!contact.bcc && contact.bcc===contact.email) ? 'BCC cannot be the same as E-mail' : ''"
                    hint="Optional - A copy of all bills and account notifications will be sent here. Partner emails are allowed here."
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
export default {
    name: 'ContactDetailsForm',
    data: () => ({
        valid: false,
        contact: {
            salutation: '',
            firstname: '',
            lastname: '',
            phone1: '',
            phone2: '',
            email: ''
        },
        rules: {
            required: [
                v => !!v || 'Required',
            ],
            firstname: [
              v => !!v || 'Required',
              v => (!v || v.length <= 45) || 'First name must be less than 45 characters',
            ],
            lastname: [
              v => !!v || 'Required',
              v => (!v || v.length <= 45) || 'Last name must be less than 45 characters',
            ],
            salutation: [
                v => (!v || v.length <= 5) || 'Title too long',
                v => !/[^A-Za-z]/.test(v) || 'Invalid title'
            ],
            phone: [
                v => !!v || 'At least one phone number is required',
                v => !/^04.*$/.test(v) || 'Mobile number must go in "Mobile Phone" field',
                v => /^(?:(?:0[2-47-8]\d{2}|(?:1300|1800))\d{6}|13\d{4})$/.test(v) || 'Must be a valid phone number'
            ],
            mobile: [
                v => !!v || 'At least one phone number is required',
                v => /^04\d{8}$/.test(v) || 'Must be a valid mobile number'
            ],
            email: [
                v => !!v || 'E-mail is required',
                v => /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) || 'Must be a valid email address'
            ],
            bcc: [
                v => !v || /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) || 'Not a valid email address'
            ],
        },
        data: {
            salutations: ['Dr', 'Miss', 'Mr', 'Mrs', 'Ms']
        },
    }),
    methods: {
        updateData(data) {
            if (data===this.contact) return;
            if (data) this.contact = { ...this.contact, ...data };
        },
    },
    watch: {
        contact: {
            handler(data) {
                if (this.contact.phone1==='61') this.contact.phone1 = '0';
                if (this.contact.phone2==='61') this.contact.phone2 = '0';
                if(this.valid){
                  data.title = data.salutation;
                  this.$emit('input',data);
                }else{
                  this.$emit('input',null);
                }
            },
            deep: true
        },
        value(data) {
            this.updateData(data);
        },
        valid() {
            this.$emit('input', this.valid ? this.contact : null)
        }
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        showBcc: {
            type: Boolean,
            default: false
        },
        hints:{
          type: Object,
          default: function( ){
            return {};
          },
        }
    },
  updated( ){
    /**
     * This is here to provide failed validation messages for first of last names that exceed 45 characters.
     * The logic is that the contact records only accepts first and last names of 45 character or less.
     * So we need to validate this limit here. Problem is the values for firstname and last name are prefilled
     * from the previous form and hence validation is not triggered.
     *
     * Validation can be triggered for the whole form however this looks ugly as it triggers failed validation on inputs
     * the user has no yet interacted with. I tried many different ways to try an only validate the specific inputs without success.
     * I did manage to get an input to validated with a combination of focus and blur methods however this hack did not work for multiple inputs
     * (only one input would trigger its validation).
     *
     * Hence we run some basic checks here and if it detects the length is exceeded we trigger a validation on the whole form.
     *
     * Without this validation trick the form can have invalid data in the inputs. The validation of these inputs may never be
     * triggered and hence the user is not presented with the error message and hence have no idea why the form is not valid and
     * why they cannot proceed.
     *
     *
     */
    if((this.contact.firstname && this.contact.firstname != '' && this.contact.firstname.length > 45)
          ||(this.contact.lastname && this.contact.lastname != '' && this.contact.lastname.length > 45)){
        this.$refs.contactForm.validate( );
      }
  },

    created() {
      this.updateData(this.value);
    }
};
</script>