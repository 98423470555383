export default {
    data: () => ({
        addressData: {
            building_types: [
                { value: null, text: 'N/A' },
                { value: 'APT', text: 'Apartment' },
                { value: 'CTGE', text: 'Cottage' },
                { value: 'DUP', text: 'Duplex' },
                { value: 'FY', text: 'Factory' },
                { value: 'F', text: 'Flat' },
                { value: 'HSE', text: 'House' },
                { value: 'KSK', text: 'Kiosk' },
                { value: 'MSNT', text: 'Maisonette' },
                { value: 'MB', text: 'Marine Berth' },
                { value: 'OFF', text: 'Office' },
                { value: 'PTHS', text: 'Penthouse' },
                { value: 'RM', text: 'Room' },
                { value: 'SHED', text: 'Shed' },
                { value: 'SHOP', text: 'Shop' },
                { value: 'SITE', text: 'Site' },
                { value: 'SL', text: 'Stall' },
                { value: 'STU', text: 'Studio' },
                { value: 'SE', text: 'Suite' },
                { value: 'TNHS', text: 'Townhouse' },
                { value: 'U', text: 'Unit' },
                { value: 'VLLA', text: 'Villa' },
                { value: 'WARD', text: 'Ward' },
                { value: 'WE', text: 'Warehouse' },
            ],
            floor_types: [
                { value: null, text: 'N/A' },
                { value: 'B', text: 'Basement' },
                { value: 'FL', text: 'Floor' },
                { value: 'G', text: 'Ground' },
                { value: 'L', text: 'Level' },
                { value: 'LG', text: 'Lower Ground' },
                { value: 'M', text: 'Mezzanine' },
                { value: 'UG', text: 'Upper Ground' },
            ],
            street_types: [
                { value: null, text: 'N/A' },
                { value: 'ALLY', text: 'Alley' },
                { value: 'ARC', text: 'Arcade' },
                { value: 'AVE', text: 'Avenue' },
                { value: 'BVD', text: 'Boulevard' },
                { value: 'BYPA', text: 'Bypass' },
                { value: 'CCT', text: 'Circuit' },
                { value: 'CL', text: 'Close' },
                { value: 'CRN', text: 'Corner' },
                { value: 'CT', text: 'Court' },
                { value: 'CRES', text: 'Crescent' },
                { value: 'CDS', text: 'Cul-de-sac' },
                { value: 'DR', text: 'Drive' },
                { value: 'ESP', text: 'Esplanade' },
                { value: 'GRN', text: 'Green' },
                { value: 'GR', text: 'Grove' },
                { value: 'HWY', text: 'Highway' },
                { value: 'JNC', text: 'Junction' },
                { value: 'LANE', text: 'Lane' },
                { value: 'LINK', text: 'Link' },
                { value: 'MEWS', text: 'Mews' },
                { value: 'PDE', text: 'Parade' },
                { value: 'PL', text: 'Place' },
                { value: 'RDGE', text: 'Ridge' },
                { value: 'RD', text: 'Road' },
                { value: 'SQ', text: 'Square' },
                { value: 'ST', text: 'Street' },
                { value: 'TCE', text: 'Terrace' },
            ],
            street_suffixes: [
                { value: null, text: 'N/A' },
                { value: 'CN', text: 'Central' },
                { value: 'E', text: 'East' },
                { value: 'EX', text: 'Extension' },
                { value: 'LR', text: 'Lower' },
                { value: 'N', text: 'North' },
                { value: 'NE', text: 'North East' },
                { value: 'NW', text: 'North West' },
                { value: 'S', text: 'South' },
                { value: 'SE', text: 'South East' },
                { value: 'SW', text: 'South West' },
                { value: 'UP', text: 'Upper' },
                { value: 'W', text: 'West' },
            ],
            states: [
                { value: 'NSW', text: 'New South Wales' },
                { value: 'VIC', text: 'Victoria' },
                { value: 'QLD', text: 'Queensland' },
                { value: 'SA', text: 'South Australia' },
                { value: 'WA', text: 'Western Australia' },
                { value: 'TAS', text: 'Tasmania' },
                { value: 'ACT', text: 'Australian Capital Territory' },
                { value: 'NT', text: 'Northern Territory' },
            ],
        },
    }),
};
