<template>
    <span v-if="!!data">
        <span v-if="data.building_type">{{addressData.building_types.find( ({ value }) => value === data.building_type).text}} {{data.building_number}}<span v-html="multiline ? '<br/>' : ', '" /></span>
        <span v-if="data.floor_type">{{addressData.floor_types.find( ({ value }) => value === data.floor_type).text}}<span v-if="data.floor_number"> {{data.floor_number}}</span><span v-html="multiline ? '<br/>' : ', '" /></span>
        {{data.street_number}} {{data.street_name}}<span v-if="data.street_type"> {{addressData.street_types.find( ({ value }) => value === data.street_type).text}}</span><span v-if="data.street_suffix"> {{addressData.street_suffixes.find( ({ value }) => value === data.street_suffix).text}}</span><span v-html="multiline ? '<br/>' : ', '" />
        {{data.suburb}} {{data.state}} {{data.postcode}}
    </span>
</template>
<script>
    import addressDataMixin from "../../../mixins/addressDataMixin";
    export default {
        name: 'AddressText',
        mixins: [addressDataMixin],
        props: {
            data: {
                type: Object,
                default: null
            },
            multiline: {
                type: Boolean,
                default: false
            }
        },
    };
</script>