<template>
    <v-form v-model="valid" v-on:submit.prevent="">
        <v-text-field
            outlined
            v-model="id"
            label="Display Name *"
            :error-messages="(!!id && currentQuery===id && !valid) ? validErrorMessage : null"
            :rules="rules"
            hint="This is the name that will be displayed in the portal"
            :counter="41"
        >
            <template v-slot:append>
                <div v-if="!!id && !loading && id===currentQuery">
                    <IconWithTooltip
                        v-if="valid"
                        tooltip="Display name is unique"
                        icon="mdi-check"
                        color="success"
                    />
                    <IconWithTooltip
                        v-if="!valid"
                        tooltip="Display name already in use"
                        icon="mdi-alert"
                        color="error"
                    />
                </div>
                <v-progress-circular
                    v-if="loading"
                    :width="3"
                    :size="24"
                    color="primary"
                    indeterminate
                />
            </template>
        </v-text-field>
    </v-form>
</template>

<script>
const IDRegex = /^[A-Z0-9][\w\d \-\,_\.&\']*[\w\d]$/;
import apiMixin from '../../../mixins/apiMixin';
import IconWithTooltip from '../IconWithTooltip';
export default {
    name: 'CustomerIDField',
    components: { IconWithTooltip },
    mixins: [apiMixin],
    data: () => ({
        id: '',
        valid: false,
        validErrorMessage: null,
        timer: null,
        loading: false,
        currentQuery: '',
        rules: [
            v => !!v || "Display name is required",
            v => IDRegex.test(v) || "Display name is not valid"
        ]
    }),
    methods: {
        async validate(id) {
            this.loading = true;
            this.currentQuery = id;
            const response = await this.Api.send('post','customers/validate_id', {id: id}, true);
            if (response.success) {
                if (id===this.id){
                  this.$emit('input', id);
                }
                this.valid = true;
                this.validErrorMessage = null;
            } else {
                if (this.value) this.$emit('input', null);
                this.validErrorMessage = response.data.description;
                this.valid = false;
            }
            this.loading = false;
        },
    },
    watch: {
        id(val) {
            clearTimeout(this.timer);
            this.$emit('input', null);
            if (val && !IDRegex.test(val)) {
                val = val.replaceAll('  ', ' ');
                val = val.trimStart().charAt(0).toUpperCase() + val.trimStart().slice(1);
                this.id = val;
                this.currentQuery = '';
                return;
            }
            if (val && IDRegex.test(val)) {
                this.timer = setTimeout(() => {
                    if (val !== this.currentQuery && val != this.currentValue) {
                      this.validate(val);
                    }
                }, 500);
            } else {
                this.valid = false;
                this.currentQuery = '';
            }
        },
        value(value) {
            if (value && this.id!==value) this.id = value;
        }
    },
    props: {
        value: {
            type: String,
            default: null
        },
        currentValue:{
          type:String,
          default:null
        }
    },
    created() {
        if (this.value) this.id = this.value;
    }
};
</script>

<style scoped>

</style>